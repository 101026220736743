import React from 'react';
import { bool, string } from 'prop-types';
import Logo from '../../../atoms/Logo';

import Tag from '../../../atoms/Tag';
import { P } from '../../../atoms/Text';
import { InfoButton, OverlayWrapper, TopLeft, Block } from './styles';
import { textSizes } from '../../../../assets/theme';

const renderTitles = titles =>
  titles.split('\\n').map(title => (
    <Tag key={title} size="lg">
      {title}
    </Tag>
  ));

const VideoOverlay = ({
  active,
  title,
  onClick,
  onClickFullscreen,
  isFullscreen,
}) => (
  <>
    <OverlayWrapper onClick={onClick}>
      <TopLeft $active={active}>
        <Block>
          <Logo />
        </Block>
        <Block centered>{title ? renderTitles(title) : null}</Block>
        <Block />
      </TopLeft>
    </OverlayWrapper>
    <InfoButton $active={active} onClick={onClickFullscreen} data-hoverable postition="bl">
      <P size={textSizes.lg} weight="600" selectable={false} letterCase="lower">
        {isFullscreen ? 'EXIT FULLSCREEN' : 'FULLSCREEN'}
      </P>
    </InfoButton>
  </>
);

VideoOverlay.propTypes = {
  active: bool,
  title: string.isRequired,
};

export default VideoOverlay;
