import React from 'react';
import styled from 'styled-components';
import { Label } from '../../atoms/Text';
import { colours } from '../../../assets/theme';

export const VideoWrapper = styled.div`
  width: 100vw;
  height: 100vh;
  z-index: 1;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  // cursor: ${props => (props.$active ? 'pointer' : 'none')};
  cursor: none;
  display: flex;
  justify-content: center;
  align-items: center;
`;
export const Iframe = styled.iframe`
  z-index: -1;
  width: 100vw;
  height: 100vh;
  pointer-events: none;
  position: absolute;
`;
export const Overlay = styled.div`
  z-index: 1;
  position: fixed;
  height: 100vh;
  width: 100vw;
`;

const ButtonWrapper = styled.div`
  div {
    padding: 1em 2em;
    background-color: #ffffffba;
    display: flex;
    flex-direction: row;
    align-items: center;
    border: 1px solid ${colours.black};
  }

  label {
    color: ${colours.black};
    margin-left: 8px;
    font-size: 20px;
  }

  :hover div {
    background-color: #ffffff;
  }
`;

export const PlayButton = props => (
  <ButtonWrapper data-hoverable {...props}>
    <div>
      <Label>JOIN STREAM</Label>
    </div>
  </ButtonWrapper>
);

export const ActionButton = styled.button`
  font-size: 18px;
`;
