import React, { useEffect, useRef, useState } from 'react';
import {
  instanceOf,
  number,
  object,
  shape,
  string,
} from 'prop-types';

import Chat from '../../atoms/Chat';
import Overlay from './VideoOverlay';
import { VideoWrapper, Iframe, PlayButton } from './styles';
import config from '../../../../config';
import { useToggle } from '../../../hooks/utility';
import { useKeyboardShortcut } from '../../../hooks/keyboardHooks';

// if (typeof window !== 'undefined') {
//   import { PeerTubePlayer } from '@peertube/embed-api';
// }

const Video = ({ video }) => {
  const [isPlaying, setPlaying] = useState(false);
  const [isFullscreen, toggleIsFullscreen] = useToggle(false);
  const videoiFrame = useRef(null);
  const overlayTimeout = useRef(null);
  const [videoReady, setVideoReady] = useState(false);
  const [overlayActive, setOverlayActiveState] = useState(true);
  const ptVideo = useRef(null);
  const resolutions = useRef(null);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      const setVideo = async () => {
        const { PeerTubePlayer } = await import('@peertube/embed-api');
        const player = new PeerTubePlayer(videoiFrame.current);
        await player.ready;

        ptVideo.current = player;
        player.setVolume(100);
        setVideoReady(true);
        resolutions.current = await player.getResolutions();

        // console.log(resolutions.current); // logs []
      };
      setVideo();
    }
  }, []);

  const playVideo = () => {
    const { current: player } = ptVideo;
    if (!videoReady) return;

    setPlaying(true);
    try {
      player.play();
    } catch (error) {
      console.log({ error });
      setOverlayActiveState(true);
      setPlaying(false);
    }
  };
  const pauseVideo = () => {
    const { current: player } = ptVideo;
    setPlaying(false);
    setOverlayActiveState(true);
    try {
      player.pause();
    } catch (error) {
      console.log({ error });
      setPlaying(true);
      setOverlayActiveState(false);
    }
  };

  const toggleVideo = () => {
    if (isPlaying) {
      pauseVideo();
    } else {
      playVideo();
    }
  };

  const toggleFullscreen = () => {
    toggleIsFullscreen();
    if (!document.fullscreenElement) {
      document.documentElement.requestFullscreen();
    } else if (document.exitFullscreen) {
      document.exitFullscreen();
    }
  };

  useKeyboardShortcut(['f'], toggleFullscreen);
  useKeyboardShortcut(['space'], toggleVideo);

  const activateOverlay = () => {
    clearTimeout(overlayTimeout.current);
    setOverlayActiveState(true);

    overlayTimeout.current = setTimeout(
      () => setOverlayActiveState(false),
      2000,
    );
  };

  return (
    <VideoWrapper
      $active={overlayActive || !isPlaying}
      onMouseMove={activateOverlay}
    >
      <Overlay
        onClick={toggleVideo}
        active={overlayActive || !isPlaying}
        title={video.title}
        onClickFullscreen={toggleFullscreen}
        isFullscreen={isFullscreen}
      />
      {!isPlaying && <PlayButton />}
      <Iframe
        sandbox="allow-same-origin allow-scripts allow-popups"
        src={`${config.peertubeRoot}${video.embedPath}?api=1&controls=false&vq=hd1080`}
        frameborder="0"
        allowfullscreen
        allow="autoplay"
        ref={videoiFrame}
      />
    </VideoWrapper>
  );
};

Video.propTypes = {
  video: shape({
    account: object,
    category: object,
    channel: object,
    description: string,
    duration: number,
    embedPath: string,
    end: instanceOf(Date),
    id: string,
    language: object,
    previewPath: string,
    start: instanceOf(Date),
    state: object,
    title: string,
    videoUrl: string,
    views: number,
  }),
};

export default Video;
