import React from 'react';
import { withTheme } from 'styled-components';

import Link from '../Link';
import { Pill, LinkPill } from './styles';
import { P, H3 } from '../Text';
import { bool, string } from 'prop-types';

const Tag = ({ label, inverted, theme, children, size = 'sm', ...props }) => {
  return (
    <Pill inverted={inverted} size={size} {...props}>
      {size === 'lg' ? (
        <H3 selectable={false} colour={inverted ? theme.background : theme.foreground}>
          {children || label}
        </H3>
      ) : (
        <P selectable={false} colour={inverted ? theme.background : theme.foreground}>
          {children || label}
        </P>
      )}
    </Pill>
  );
};

export const LinkTag = withTheme(
  ({ href, to, label, inverted, theme, newtab, renderIcon: Icon, ...props }) => {
    return (
      <Link href={href} to={to} newtab={newtab}>
        <LinkPill inverted={inverted} href={href} to={to} {...props}>
          {Icon ? <Icon size={16} /> : null}
          <P selectable={false} colour={inverted ? theme.background : theme.foreground}>
            {label}
          </P>
        </LinkPill>
      </Link>
    );
  },
);

Tag.propTypes = {
  label: string,
  inverted: bool,
};

export default withTheme(Tag);
