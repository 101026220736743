import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import styled from 'styled-components';

import Page from '../organisms/Page';
import { useUiStore, useLivestreamStore } from '../../store';
import { mediaQuery } from '../../assets/theme';
import LivestreamVideo from '../molecules/Livestream';

const IndexPage = (pageData) => {
  const [video, isLive] = useLivestreamStore(store => [store.video, store.isLive]);

  const theme = useUiStore(store => store.theme);
  const {
    strapiHomePage: {
      Meta: {
        page_title_fr,
        page_title_en,
        meta_image_dark: { publicURL: imageDark },
        meta_image_light: { publicURL: imageLight },
      },
    },
    strapiSiteMeta: { site_image },
  } = useStaticQuery(graphql`
    query indexPageQuery {
      strapiHomePage {
        Meta {
          page_title_fr
          page_title_en
          meta_image_dark {
            publicURL
          }
          meta_image_light {
            publicURL
          }
        }
      }
      strapiSiteMeta {
        description_en
        description_fr
        site_image {
          publicURL
        }
      }
    }
  `);

  const getBgImg = () => {
    // if (format(new Date(), 'dd.MM.yy') === '14.06.21') {
    //   return 'https://cms.looseantenna.fm/uploads/gf_97f58342ec.png';
    // }
    return theme === 'light' ? imageLight : imageDark;
  };

  return (
    <Page title={{ en: page_title_en, fr: page_title_fr }} showTitle={false} data={pageData}>
      {isLive ?
        <LivestreamVideo video={video} /> : (
          <CoverWrapper>
            {site_image && <Img src={getBgImg()} />}
          </CoverWrapper>
        )}
    </Page>
  );
};

const CoverWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  height: calc(100vh - 152px);
  overflow-y: scroll;
  overflow-x: hidden;

  &::-webkit-scrollbar {
    display: none;
  }
`;

const Img = styled.img`
  user-drag: none;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-drag: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  max-height: 75vh;

  ${mediaQuery.lessThan('md')`
    position: relative;
    right: 45vw;
  `}
`;

export default IndexPage;
