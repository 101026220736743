import styled, { css } from 'styled-components';
import { colours } from '../../../../assets/theme';
// import Button from '../Button';

export const OverlayWrapper = styled.div`
  z-index: 2;
  position: fixed;
  height: 100vh;
  width: 100vw;
  /* pointer-events: none; */
`;

export const TopLeft = styled.div`
  opacity: 0;
  transform: translateY(-20%);
  transition: all 0.2s ease-in-out;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;

  ${props =>
    props.$active &&
    css`
      transform: translateY(0%);
      opacity: 1;
    `};
`;

export const Block = styled.div`
  flex: 1;
  ${props => props.centered && `
    display: flex;
    align-items: center;
    justify-content: center;
  `}
`;

export const InfoButton = styled.button`
  opacity: 0.001;
  transform: translateY(
    ${props => (props.postition === 'bl' ? '20%' : '-20%')}
  );
  transition: all 0.2s ease-in-out;
  transition-delay: 0.2s;
  position: fixed;
  right: ${props => (props.postition === 'bl' ? 'initial' : '32px')};
  top: ${props => (props.postition === 'bl' ? 'initial' : '32px')};
  bottom: ${props => (props.postition === 'bl' ? '0' : 'initial')};
  left: ${props => (props.postition === 'bl' ? '32px' : 'initial')};
  z-index: 100;
  width: auto;
  border-top: 1px solid ${props => props.theme?.foreground ?? colours.white};
  border-left: 1px solid ${props => props.theme?.foreground ?? colours.white};
  border-right: 1px solid ${props => props.theme?.foreground ?? colours.white};
  background-color: ${props => props.theme?.background ?? colours.black};
  padding: 4px 8px 0 8px;
  cursor: none;

  ${props =>
    props.$active &&
    css`
      transform: translateY(0%);
      opacity: 1;
    `};

  &:hover {
    opacity: 0.7;
  }
`;
