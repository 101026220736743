import styled, { css } from 'styled-components';

export const Pill = styled.div`
  padding: ${props => (props.size === 'lg' ? '6px 12px 3px' : '4px 8px 2px')};
  border: 1px solid ${props => (props.inverted ? props.theme.background : props.theme.foreground)};
  background-color: ${props => (props.inverted ? props.theme.foreground : props.theme.background)};
  border-radius: 32px;
  display: inline-block;
`;

export const LinkPill = styled(Pill)`
  display: inline-flex;
  align-items: center;

  svg {
    margin-right: 8px;
    position: relative;
    top: -1px;

    path {
      fill: ${({ theme }) => theme.foreground} !important;
    }
  }

  &:hover {
    border: 1px solid ${props => (props.inverted ? props.theme.foreground : props.theme.background)};
    background-color: ${props => (props.inverted ? props.theme.background : props.theme.foreground)};
    color: ${({ theme, inverted }) => (inverted ? theme.foreground : theme.background)} !important;

    p {
      color: ${({ theme, inverted }) => (inverted ? theme.foreground : theme.background)} !important;
    }
    svg path {
      fill: ${({ theme }) => theme.background} !important;
    }
  }
`;
